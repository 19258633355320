import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KModal,
  KPanel,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  MultiSelect,
  NumberInput,
  TextArea,
  TextInput
} from "../../components/web-components";
import { KSelectBox, Spacer } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { IKartMeslekBirligiTR_ScreenBase } from "./ikartmeslekbirligitr-base";

import { AksiyonKart_Screen } from "../aksiyonkart/aksiyonkart";
import { CariIlgiliKisiler_Screen } from "../cariilgilikisiler/cariilgilikisiler";
import { CariSozlesmeler_Screen } from "../carisozlesmeler/carisozlesmeler";
import { HataKontrol_Screen } from "../hatakontrol/hatakontrol";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { QuarterChange_Screen } from "../quarterchange/quarterchange";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class IKartMeslekBirligiTR_Screen extends IKartMeslekBirligiTR_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              ikartmeslekbirligitr_889558_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              ikartmeslekbirligitr_788213_value: this.state.IKartMeslekBirligiTR?.at?.(0)?.musteriID ?? undefined,
              ikartmeslekbirligitr_941181_value: this.state.IKartMeslekBirligiTR?.at?.(0)?.sektorelAd ?? undefined,
              ikartmeslekbirligitr_531670_value: this.state.IKartMeslekBirligiTR?.at?.(0)?.yapimAdi ?? undefined,
              ikartmeslekbirligitr_120240_value: this.state.IKartMeslekBirligiTR?.at?.(0)?.kanal ?? undefined,
              ikartmeslekbirligitr_626253_value: this.state.IKartMeslekBirligiTR?.at?.(0)?.sozlesmeDonemID ?? undefined,
              ikartmeslekbirligitr_50856_value: this.state.IKartMeslekBirligiTR?.at?.(0)?.sozlesmeDurumuID ?? undefined,
              ikartmeslekbirligitr_387331_value: undefined,
              ikartmeslekbirligitr_846368_value: this.state.IKartMeslekBirligiTR?.at?.(0)?.onemliNot ?? undefined,
              ikartmeslekbirligitr_693150_value: undefined,
              ikartmeslekbirligitr_758566_value: undefined,
              ikartmeslekbirligitr_75605_value: undefined,
              ikartmeslekbirligitr_914269_value: undefined,
              ikartmeslekbirligitr_43802_value: undefined,
              ikartmeslekbirligitr_862887_value: undefined,
              ikartmeslekbirligitr_364113_value: undefined,
              ikartmeslekbirligitr_725999_value: "c0aa9184-625a-4539-c510-08da52e50202",
              ikartmeslekbirligitr_311071_value: undefined,
              ikartmeslekbirligitr_427048_value: undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="ikartmeslekbirligitr_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="658116"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="572685"
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="138204"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="83067"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.IKartMeslekBirligiTRComponent_83067_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="ikartmeslekbirligitr_889558_value">
                        <Image
                          id="889558"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="55603"
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="785916"
                      value={ReactSystemFunctions.translate(this.ml, 785916, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="659324"
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    xxl={2}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Icon
                      id="996082"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.IKartMeslekBirligiTRComponent_996082_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontSize: "24px",
                          color: "rgba(172, 38, 42, 1)"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="ikartmeslekbirligitr_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="902140"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="833259"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="853814"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="764585"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="879496"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderTopLeftRadius: 12,
                              borderTopRightRadius: 12,
                              borderBottomRightRadius: 12,
                              borderBottomLeftRadius: 12,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              paddingBottom: 16,
                              width: "1200px",
                              alignItems: "flex-start",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="376827"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderRightWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "flex-start",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="329794"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="405389"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="70279"
                                  value={ReactSystemFunctions.translate(this.ml, 70279, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="ikartmeslekbirligitr_788213_value">
                                  <KSelectBox
                                    id="788213"
                                    kuikaRef={this.ikartmeslekbirligitr_788213_value_kuikaSelectBoxRef}
                                    options={this.state.sbCariList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      788213,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={true}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="cariAd"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="429694"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="301898"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="597603"
                                  value={ReactSystemFunctions.translate(this.ml, 597603, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="ikartmeslekbirligitr_941181_value">
                                  <TextInput
                                    id="941181"
                                    kuikaRef={this.ikartmeslekbirligitr_941181_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      941181,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "36px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="658573"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="745914"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="924165"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 16,
                                      paddingBottom: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="112742"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="911053"
                                      value={ReactSystemFunctions.translate(this.ml, 911053, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="ikartmeslekbirligitr_531670_value">
                                      <TextInput
                                        id="531670"
                                        kuikaRef={this.ikartmeslekbirligitr_531670_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          531670,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "36px",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="44395"
                                  visibility={this.state.isComp44395Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 16,
                                      paddingBottom: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="943262"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="536198"
                                      value={ReactSystemFunctions.translate(this.ml, 536198, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="ikartmeslekbirligitr_120240_value">
                                      <MultiSelect
                                        id="120240"
                                        kuikaRef={this.ikartmeslekbirligitr_120240_value_kuikaMultiSelectRef}
                                        options={this.state.sbSozlesmeKanal}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          120240,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        mode="tags"
                                        showSearch={true}
                                        autoRefresh={true}
                                        datavaluefield="id"
                                        datatextfield="tanim"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></MultiSelect>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="589567"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 16,
                                      paddingBottom: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="110899"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="908497"
                                      visibility={this.state.isComp908497Visible}
                                      value={ReactSystemFunctions.translate(this.ml, 908497, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="ikartmeslekbirligitr_626253_value">
                                      <KSelectBox
                                        id="626253"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.IKartMeslekBirligiTRComponent_626253_onChange();
                                        }}
                                        visibility={this.state.isComp626253Visible}
                                        kuikaRef={this.ikartmeslekbirligitr_626253_value_kuikaSelectBoxRef}
                                        options={this.state.sbSozlesmeDonem}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          626253,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={true}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="tanim"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "36px",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="588708"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="556787"
                                      value={ReactSystemFunctions.translate(this.ml, 556787, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="ikartmeslekbirligitr_50856_value">
                                      <KSelectBox
                                        id="50856"
                                        kuikaRef={this.ikartmeslekbirligitr_50856_value_kuikaSelectBoxRef}
                                        options={this.state.sbSozlesmeDurumu}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          50856,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={true}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="tanim"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "36px",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="855595"
                                  visibility={this.state.isComp855595Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 64,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="115888"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="238965"
                                      value={ReactSystemFunctions.translate(this.ml, 238965, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="978718"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="782963"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IKartMeslekBirligiTRComponent_782963_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="add_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 16,
                                          fontSize: "24px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="58005"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 0,
                                      paddingRight: 16,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="15381"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "flex-start",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="435813"
                                      visibility={this.state.isComp435813Visible}
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(252, 250, 231, 1)",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="333802"
                                        xs={18}
                                        sm={18}
                                        md={18}
                                        lg={18}
                                        xl={18}
                                        xxl={18}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="ikartmeslekbirligitr_387331_value">
                                          <KSelectBox
                                            id="387331"
                                            kuikaRef={this.ikartmeslekbirligitr_387331_value_kuikaSelectBoxRef}
                                            options={this.state.sbMusteriTuruList}
                                            placeholder={ReactSystemFunctions.translate(
                                              this.ml,
                                              387331,
                                              "placeholder",
                                              this.defaultML
                                            )}
                                            allowClear={true}
                                            autoClearSearchValue={true}
                                            showSearch={true}
                                            widthType="fill"
                                            containsNullItem={false}
                                            sortBy="none"
                                            datavaluefield="id"
                                            datatextfield="tanim"
                                            style={
                                              {
                                                borderBottomWidth: 1,
                                                borderColor: "rgba(155, 155, 155, 1)",
                                                borderStyle: "dotted",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(252, 250, 231, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                height: "36px",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></KSelectBox>
                                        </Form.Item>
                                      </KCol>

                                      <KCol
                                        id="285308"
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        xxl={3}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="121663"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.IKartMeslekBirligiTRComponent_121663_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="add_task"
                                          hoverText="Ekle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontSize: "24px",
                                              color: "rgba(65, 117, 5, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>

                                      <KCol
                                        id="845684"
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        xxl={3}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: 12,
                                            alignItems: "center",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="69679"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.IKartMeslekBirligiTRComponent_69679_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="highlight_off"
                                          hoverText="Vazgeç"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              textAlign: "-webkit-right",
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="578227"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="44486"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KTable
                                          id="989333"
                                          kuikaRef={this.ikartmeslekbirligitr_989333_value_kuikaTableRef}
                                          form={this.props.form}
                                          dataSource={this.state.getListMusteriTuruByCariID}
                                          size="middle"
                                          bordered={true}
                                          showHeader={false}
                                          loading={false}
                                          nodatafoundmessage={ReactSystemFunctions.translate(
                                            this.ml,
                                            989333,
                                            "nodatafoundmessage",
                                            this.defaultML
                                          )}
                                          sorter={false}
                                          pagination={false}
                                          hideTableOnEmpty={true}
                                          striped={false}
                                          stripedColor="#F5F7FA"
                                          searchable={false}
                                          filtering={false}
                                          insertRowActive={false}
                                          transformedOnMobileResolution={false}
                                          fixedHeader={false}
                                          editable={false}
                                          tableWidthMode="fit"
                                          globalSearch={false}
                                          pageSize={10}
                                          showSizeChanger={false}
                                          columnChooser={false}
                                          resizableColumns={false}
                                          insertRowPosition="top"
                                          showNoDataFound={true}
                                          multiSelect={false}
                                          export={false}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <KTableHeader
                                            id="414954"
                                            hideOnMobileResolution={false}
                                            textDirection="Default"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0
                                              } as any
                                            }
                                          ></KTableHeader>

                                          <KTableRow
                                            id="64655"
                                            hoverFontColor="red"
                                            hoverBgColor="#F5F5F5"
                                            style={
                                              {
                                                borderTopWidth: 1,
                                                borderRightWidth: 1,
                                                borderBottomWidth: 1,
                                                borderLeftWidth: 1,
                                                borderColor: "rgba(218, 218, 218, 1)",
                                                borderStyle: "solid",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <KTableColumn
                                              id="26510"
                                              title={ReactSystemFunctions.translate(
                                                this.ml,
                                                26510,
                                                "title",
                                                this.defaultML
                                              )}
                                              alwaysVisibleOnMobileResolution={false}
                                              hideFiltering={false}
                                              hideSorting={false}
                                              hideSearch={false}
                                              footerOptions="none"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            >
                                              <KRow
                                                id="272130"
                                                align="top"
                                                justify="start"
                                                horizontalGutter={0}
                                                verticalGutter={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    letterSpacing: "0.5px"
                                                  } as any
                                                }
                                              >
                                                <KCol
                                                  id="357166"
                                                  xs={22}
                                                  sm={22}
                                                  md={22}
                                                  lg={22}
                                                  xl={22}
                                                  xxl={22}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingBottom: 8,
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      letterSpacing: "0.5px"
                                                    } as any
                                                  }
                                                >
                                                  <Label
                                                    id="483786"
                                                    value="[datafield:mTuruTanim]"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "12px",
                                                        color: "rgba(47, 47, 47, 1)",
                                                        letterSpacing: "0.5px"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </KCol>

                                                <KCol
                                                  id="550692"
                                                  xs={2}
                                                  sm={2}
                                                  md={2}
                                                  lg={2}
                                                  xl={2}
                                                  xxl={2}
                                                  order={0}
                                                  pull={0}
                                                  push={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingBottom: 8,
                                                      textAlign: "-webkit-center",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(68, 68, 68, 1)",
                                                      letterSpacing: "0.5px"
                                                    } as any
                                                  }
                                                >
                                                  <Icon
                                                    id="989982"
                                                    onClick={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.IKartMeslekBirligiTRComponent_989982_onClick();
                                                    }}
                                                    showCursorPointer
                                                    iconName="remove_circle_outline"
                                                    hoverText="Sil"
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-center",
                                                        fontSize: "24px",
                                                        color: "rgba(172, 38, 42, 1)"
                                                      } as any
                                                    }
                                                  ></Icon>
                                                </KCol>
                                              </KRow>
                                            </KTableColumn>
                                          </KTableRow>
                                        </KTable>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="162363"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 48,
                                  paddingBottom: 48,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="650956"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Button
                                  id="861691"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IKartMeslekBirligiTRComponent_861691_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 861691, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 32,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 32,
                                      borderBottomLeftRadius: 32,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(172, 38, 42, 1)",
                                      borderStyle: "solid",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Button>
                              </KCol>

                              <KCol
                                id="589969"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Button
                                  id="356553"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IKartMeslekBirligiTRComponent_356553_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 356553, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="right"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 32,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 32,
                                      borderBottomLeftRadius: 32,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(172, 38, 42, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(172, 38, 42, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(255, 255, 255, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Button>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="943517"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "flex-start",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="972252"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="159706"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="600473"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingBottom: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="655165"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="516287"
                                      value={ReactSystemFunctions.translate(this.ml, 516287, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="259695"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="44312"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="ikartmeslekbirligitr_846368_value">
                                      <TextArea
                                        id="846368"
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          846368,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoSize={false}
                                        showCount={false}
                                        formatter="None"
                                        readOnly={false}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></TextArea>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="802825"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingBottom: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="294870"
                                    xs={16}
                                    sm={16}
                                    md={16}
                                    lg={16}
                                    xl={16}
                                    xxl={16}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="635010"
                                      value={ReactSystemFunctions.translate(this.ml, 635010, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="439868"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="429025"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IKartMeslekBirligiTRComponent_429025_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="add_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 16,
                                          fontSize: "24px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="102734"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="842116"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="130742"
                                      kuikaRef={this.ikartmeslekbirligitr_130742_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.getListBFormuAksiyon}
                                      size="middle"
                                      bordered={true}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        130742,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      hideTableOnEmpty={true}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      searchable={false}
                                      filtering={false}
                                      insertRowActive={false}
                                      transformedOnMobileResolution={false}
                                      fixedHeader={false}
                                      editable={false}
                                      tableWidthMode="fit"
                                      globalSearch={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      columnChooser={false}
                                      resizableColumns={false}
                                      insertRowPosition="top"
                                      showNoDataFound={true}
                                      multiSelect={false}
                                      export={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(155, 155, 155, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="865256"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "11px",
                                            color: "rgba(208, 2, 27, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="395787"
                                        hoverFontColor="red"
                                        hoverBgColor="yellow"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="124150"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            124150,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="588709"
                                            value="[datafield:aksiyonTarihi]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="790683"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            790683,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="635026"
                                            value="[datafield:nick]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="156255"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            156255,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="534677"
                                            value="[datafield:aksiyonTanim]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="178001"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            178001,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="58767"
                                            value="[datafield:aciklama]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="834451"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            834451,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Spacer
                                            id="267785"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="232864"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.IKartMeslekBirligiTRComponent_232864_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="delete"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontSize: "24px",
                                                  color: "rgba(172, 38, 42, 1)"
                                                } as any
                                              }
                                            ></Icon>

                                            <Icon
                                              id="8393"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.IKartMeslekBirligiTRComponent_8393_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="create"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontSize: "24px",
                                                  color: "rgba(172, 38, 42, 1)"
                                                } as any
                                              }
                                            ></Icon>
                                          </Spacer>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="701249"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingBottom: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="692570"
                                    xs={16}
                                    sm={16}
                                    md={16}
                                    lg={16}
                                    xl={16}
                                    xxl={16}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="450613"
                                      value={ReactSystemFunctions.translate(this.ml, 450613, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="788443"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="758058"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IKartMeslekBirligiTRComponent_758058_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="add_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 16,
                                          fontSize: "24px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="877998"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="530484"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="586219"
                                      kuikaRef={this.ikartmeslekbirligitr_586219_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.getListCariIlgiliKisiler}
                                      size="middle"
                                      bordered={true}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        586219,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      hideTableOnEmpty={true}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      searchable={false}
                                      filtering={false}
                                      insertRowActive={false}
                                      transformedOnMobileResolution={false}
                                      fixedHeader={false}
                                      editable={false}
                                      tableWidthMode="fit"
                                      globalSearch={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      columnChooser={false}
                                      resizableColumns={false}
                                      insertRowPosition="top"
                                      showNoDataFound={true}
                                      multiSelect={false}
                                      export={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(155, 155, 155, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="122815"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "11px",
                                            color: "rgba(172, 38, 42, 1)"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="870110"
                                        hoverFontColor="red"
                                        hoverBgColor="yellow"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="839802"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            839802,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="432076"
                                            value="[datafield:adSoyad]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="574033"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            574033,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="56614"
                                            value="[datafield:gorevi]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="330558"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            330558,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="164471"
                                            value="[datafield:telefon]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="754739"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            754739,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="128319"
                                            value="[datafield:eMail]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="883962"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            883962,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Spacer
                                            id="998123"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="123813"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.IKartMeslekBirligiTRComponent_123813_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="delete"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontSize: "24px",
                                                  color: "rgba(172, 38, 42, 1)"
                                                } as any
                                              }
                                            ></Icon>

                                            <Icon
                                              id="574746"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.IKartMeslekBirligiTRComponent_574746_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="create"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontSize: "24px",
                                                  color: "rgba(172, 38, 42, 1)"
                                                } as any
                                              }
                                            ></Icon>
                                          </Spacer>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="165412"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingBottom: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="794680"
                                    xs={16}
                                    sm={16}
                                    md={16}
                                    lg={16}
                                    xl={16}
                                    xxl={16}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="764802"
                                      value={ReactSystemFunctions.translate(this.ml, 764802, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="170438"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="238029"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IKartMeslekBirligiTRComponent_238029_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="add_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 16,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="754127"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="580304"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="98439"
                                      kuikaRef={this.ikartmeslekbirligitr_98439_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.getListCariSozlesmeBilgileri}
                                      size="middle"
                                      bordered={true}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        98439,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      hideTableOnEmpty={true}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      searchable={false}
                                      filtering={false}
                                      insertRowActive={false}
                                      transformedOnMobileResolution={false}
                                      fixedHeader={false}
                                      editable={false}
                                      tableWidthMode="fit"
                                      globalSearch={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      columnChooser={false}
                                      resizableColumns={false}
                                      insertRowPosition="top"
                                      showNoDataFound={true}
                                      multiSelect={false}
                                      export={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(155, 155, 155, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="445627"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "11px",
                                            color: "rgba(172, 38, 42, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="43393"
                                        hoverFontColor="red"
                                        hoverBgColor="yellow"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="901829"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            901829,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              alignItems: "center",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="843021"
                                            value="[datafield:yilDonem]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="361784"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            361784,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="561783"
                                            value="[datafield:toplamTutar]"
                                            formatter=""
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="993420"
                                          visibility={this.state.isComp993420Visible}
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            993420,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="284906"
                                            value="[datafield:donemTutar]"
                                            formatter=""
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="925870"
                                          visibility={this.state.isComp925870Visible}
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            925870,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="938284"
                                            value="[datafield:basTarihi]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="697593"
                                          visibility={this.state.isComp697593Visible}
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            697593,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="97617"
                                            value="[datafield:bitTarihi]"
                                            formatter="date"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="387286"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            387286,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Icon
                                            id="645853"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.IKartMeslekBirligiTRComponent_645853_onClick();
                                            }}
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.IKartMeslekBirligiTRComponent_645853_onChange();
                                            }}
                                            showCursorPointer
                                            visibility={this.state.isComp645853Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isNotEmpty(rowData.dosya, "")
                                            }
                                            iconName="sim_card_download"
                                            hoverText="[datafield:dosyaAd]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                fontSize: "24px",
                                                color: "rgba(155, 155, 155, 1)"
                                              } as any
                                            }
                                          ></Icon>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="161035"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            161035,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Spacer
                                            id="575038"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="482140"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.IKartMeslekBirligiTRComponent_482140_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="delete"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontSize: "24px",
                                                  color: "rgba(172, 38, 42, 1)"
                                                } as any
                                              }
                                            ></Icon>

                                            <Icon
                                              id="227884"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.IKartMeslekBirligiTRComponent_227884_onClick();
                                              }}
                                              showCursorPointer
                                              iconName="create"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  fontSize: "24px",
                                                  color: "rgba(172, 38, 42, 1)"
                                                } as any
                                              }
                                            ></Icon>
                                          </Spacer>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="837821"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingBottom: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="176042"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 16,
                                        paddingBottom: 16,
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="436358"
                                      value={ReactSystemFunctions.translate(this.ml, 436358, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="668433"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingBottom: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="610755"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="24013"
                                      kuikaRef={this.ikartmeslekbirligitr_24013_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.SozlesmeDagitIsTakipNoEsleListByImportKartPaketAnlasma}
                                      size="middle"
                                      bordered={true}
                                      showHeader={true}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        24013,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      hideTableOnEmpty={true}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      searchable={false}
                                      filtering={false}
                                      insertRowActive={false}
                                      transformedOnMobileResolution={false}
                                      fixedHeader={false}
                                      editable={false}
                                      tableWidthMode="fit"
                                      globalSearch={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      columnChooser={false}
                                      resizableColumns={false}
                                      insertRowPosition="top"
                                      showNoDataFound={true}
                                      multiSelect={false}
                                      export={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(155, 155, 155, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="182497"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "11px",
                                            color: "rgba(172, 38, 42, 1)"
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="324763"
                                        hoverFontColor="red"
                                        hoverBgColor="yellow"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="730704"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            730704,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="18628"
                                            value="[datafield:faturaTarihi]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>

                                        <KTableColumn
                                          id="315084"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            315084,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="29743"
                                            value="[datafield:ticketNo]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="165283"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="806413"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KPanel
                          id="814286"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 12,
                              borderTopRightRadius: 12,
                              borderBottomRightRadius: 12,
                              borderBottomLeftRadius: 12,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(212, 230, 252, 1)",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              maxWidth: "1200px",
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KRow
                            id="449949"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KCol
                              id="829016"
                              xs={7}
                              sm={7}
                              md={7}
                              lg={7}
                              xl={7}
                              xxl={7}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KRow
                                id="476295"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KCol
                                  id="16123"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="870616"
                                    value={ReactSystemFunctions.translate(this.ml, 870616, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(172, 38, 42, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Form.Item className="kFormItem" name="ikartmeslekbirligitr_693150_value">
                                    <TextInput
                                      id="693150"
                                      kuikaRef={this.ikartmeslekbirligitr_693150_value_kuikaTextInputRef}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        693150,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      bordered={true}
                                      disabled={false}
                                      type="text"
                                      iconColor="rgba(0, 0, 0, 1)"
                                      formatter="None"
                                      isCharOnly={false}
                                      autoComplete={true}
                                      style={
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: "rgba(155, 155, 155, 1)",
                                          borderStyle: "dotted",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          height: "36px",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></TextInput>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="181786"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 2,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="470309"
                                    value={ReactSystemFunctions.translate(this.ml, 470309, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingLeft: 2,
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(172, 38, 42, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Form.Item className="kFormItem" name="ikartmeslekbirligitr_758566_value">
                                    <KSelectBox
                                      id="758566"
                                      kuikaRef={this.ikartmeslekbirligitr_758566_value_kuikaSelectBoxRef}
                                      options={this.state.sbQuarterYearList}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        758566,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      autoClearSearchValue={true}
                                      showSearch={false}
                                      widthType="fill"
                                      containsNullItem={false}
                                      sortBy="none"
                                      datavaluefield="quarterYear"
                                      datatextfield="quarterYear"
                                      style={
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: "rgba(155, 155, 155, 1)",
                                          borderStyle: "dotted",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          height: "36px",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></KSelectBox>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="677587"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="534076"
                                    value={ReactSystemFunctions.translate(this.ml, 534076, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingLeft: 2,
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(172, 38, 42, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Form.Item className="kFormItem" name="ikartmeslekbirligitr_75605_value">
                                    <KSelectBox
                                      id="75605"
                                      kuikaRef={this.ikartmeslekbirligitr_75605_value_kuikaSelectBoxRef}
                                      options={this.state.sbQuarterList}
                                      placeholder={ReactSystemFunctions.translate(
                                        this.ml,
                                        75605,
                                        "placeholder",
                                        this.defaultML
                                      )}
                                      allowClear={false}
                                      autoClearSearchValue={true}
                                      showSearch={false}
                                      widthType="fill"
                                      containsNullItem={false}
                                      sortBy="none"
                                      datavaluefield="id"
                                      datatextfield="quarterKod"
                                      style={
                                        {
                                          borderBottomWidth: 1,
                                          borderColor: "rgba(155, 155, 155, 1)",
                                          borderStyle: "dotted",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderLeftWidth: 0,
                                          height: "36px",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></KSelectBox>
                                  </Form.Item>
                                </KCol>
                              </KRow>
                            </KCol>

                            <KCol
                              id="828912"
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 1,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Label
                                id="961625"
                                value={ReactSystemFunctions.translate(this.ml, 961625, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(172, 38, 42, 1)"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="ikartmeslekbirligitr_914269_value">
                                <MultiSelect
                                  id="914269"
                                  onChange={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IKartMeslekBirligiTRComponent_914269_onChange();
                                  }}
                                  kuikaRef={this.ikartmeslekbirligitr_914269_value_kuikaMultiSelectRef}
                                  options={this.state.spGetSozlesmeDagitilacakFaturaNoList}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    914269,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  autoClearSearchValue={true}
                                  mode="tags"
                                  showSearch={true}
                                  autoRefresh={true}
                                  datavaluefield="logoFaturaNo"
                                  datatextfield="logoFaturaNo"
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      height: "36px",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></MultiSelect>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="706788"
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              xxl={3}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 1,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Label
                                id="386196"
                                value={ReactSystemFunctions.translate(this.ml, 386196, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(172, 38, 42, 1)"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="ikartmeslekbirligitr_43802_value">
                                <NumberInput
                                  id="43802"
                                  editability={this.state.isComp43802Enabled}
                                  decimalSeparator="."
                                  decimalScale={2}
                                  disabled={false}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    43802,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  fixedDecimalScale={false}
                                  allowNegative={true}
                                  allowEmptyFormatting={false}
                                  isThousandSeperatorOn={true}
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      height: "36px",
                                      textAlign: "-webkit-right",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(47, 47, 47, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></NumberInput>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="332995"
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              xxl={3}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 1,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Label
                                id="898344"
                                value={ReactSystemFunctions.translate(this.ml, 898344, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(172, 38, 42, 1)"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="ikartmeslekbirligitr_862887_value">
                                <KSelectBox
                                  id="862887"
                                  kuikaRef={this.ikartmeslekbirligitr_862887_value_kuikaSelectBoxRef}
                                  options={this.state.sbTelifTurList}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    862887,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  autoClearSearchValue={true}
                                  showSearch={false}
                                  widthType="fill"
                                  containsNullItem={false}
                                  sortBy="none"
                                  datavaluefield="id"
                                  datatextfield="telifTanim"
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      height: "36px",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></KSelectBox>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="616311"
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              xxl={3}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 1,
                                  paddingBottom: 8,
                                  paddingLeft: 1,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Label
                                id="430154"
                                value={ReactSystemFunctions.translate(this.ml, 430154, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(172, 38, 42, 1)"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="ikartmeslekbirligitr_364113_value">
                                <KSelectBox
                                  id="364113"
                                  kuikaRef={this.ikartmeslekbirligitr_364113_value_kuikaSelectBoxRef}
                                  options={this.state.sbIMBExcelTur}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    364113,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  autoClearSearchValue={true}
                                  showSearch={false}
                                  widthType="fill"
                                  containsNullItem={false}
                                  sortBy="none"
                                  datavaluefield="kod"
                                  datatextfield="tanim"
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      height: "36px",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></KSelectBox>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="443236"
                              xs={2}
                              sm={2}
                              md={2}
                              lg={2}
                              xl={2}
                              xxl={2}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-end",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="ikartmeslekbirligitr_725999_value">
                                <Image
                                  id="725999"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IKartMeslekBirligiTRComponent_725999_onClick();
                                  }}
                                  showCursorPointer
                                  zoomOnClick={false}
                                  imageFit="fit"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      height: "48px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Image>
                              </Form.Item>
                            </KCol>
                          </KRow>

                          <KRow
                            id="414039"
                            visibility={this.state.isComp414039Visible}
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KCol
                              id="246947"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Label
                                id="401551"
                                value={ReactSystemFunctions.translate(this.ml, 401551, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(172, 38, 42, 1)"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="ikartmeslekbirligitr_311071_value">
                                <NumberInput
                                  id="311071"
                                  decimalSeparator="."
                                  decimalScale={2}
                                  disabled={false}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    311071,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  fixedDecimalScale={false}
                                  allowNegative={true}
                                  allowEmptyFormatting={false}
                                  isThousandSeperatorOn={true}
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      height: "36px",
                                      textAlign: "-webkit-right",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(47, 47, 47, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></NumberInput>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="815964"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Label
                                id="759978"
                                value={ReactSystemFunctions.translate(this.ml, 759978, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(172, 38, 42, 1)"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="ikartmeslekbirligitr_427048_value">
                                <NumberInput
                                  id="427048"
                                  decimalSeparator="."
                                  decimalScale={2}
                                  disabled={false}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    427048,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  fixedDecimalScale={false}
                                  allowNegative={true}
                                  allowEmptyFormatting={false}
                                  isThousandSeperatorOn={true}
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      height: "36px",
                                      textAlign: "-webkit-right",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(47, 47, 47, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></NumberInput>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="925015"
                              visibility={this.state.isComp925015Visible}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></KCol>
                          </KRow>

                          <KRow
                            id="259736"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 16,
                                paddingBottom: 0,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KCol
                              id="591770"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "dotted",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Label
                                id="453101"
                                value={ReactSystemFunctions.translate(this.ml, 453101, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(172, 38, 42, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="567036"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KCol
                              id="38637"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KTable
                                id="437247"
                                kuikaRef={this.ikartmeslekbirligitr_437247_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.ImportInfoMeslekBirligiTRListByPaketAnlasmaID}
                                size="middle"
                                bordered={true}
                                showHeader={true}
                                loading={false}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  437247,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                sorter={false}
                                pagination={false}
                                hideTableOnEmpty={true}
                                striped={false}
                                stripedColor="#F5F7FA"
                                searchable={false}
                                filtering={false}
                                insertRowActive={false}
                                transformedOnMobileResolution={false}
                                fixedHeader={false}
                                editable={false}
                                tableWidthMode="fit"
                                globalSearch={false}
                                pageSize={10}
                                showSizeChanger={false}
                                columnChooser={false}
                                resizableColumns={false}
                                insertRowPosition="top"
                                showNoDataFound={true}
                                multiSelect={false}
                                export={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(155, 155, 155, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="971507"
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="29836"
                                  hoverFontColor="red"
                                  hoverBgColor="yellow"
                                  style={
                                    {
                                      borderBottomWidth: 1,
                                      borderColor: "rgba(155, 155, 155, 1)",
                                      borderStyle: "dotted",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="842805"
                                    title={ReactSystemFunctions.translate(this.ml, 842805, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Spacer
                                      id="113676"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 4,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="522651"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.IKartMeslekBirligiTRComponent_522651_onClick();
                                        }}
                                        showCursorPointer
                                        iconName="published_with_changes"
                                        hoverText="Quarter Değiştir"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontSize: "24px",
                                            color: "rgba(172, 38, 42, 1)"
                                          } as any
                                        }
                                      ></Icon>

                                      <Label
                                        id="549299"
                                        value="[datafield:quarterYear]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="702170"
                                        value="[datafield:quarterKod]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="364157"
                                        value="[datafield:yilDonem]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            paddingLeft: 4,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </Spacer>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="229869"
                                    title={ReactSystemFunctions.translate(this.ml, 229869, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="414647"
                                      value="[datafield:excelDosyaAd]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="674312"
                                    title={ReactSystemFunctions.translate(this.ml, 674312, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="183110"
                                      value="[datafield:telifTur]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="427879"
                                    title={ReactSystemFunctions.translate(this.ml, 427879, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="665598"
                                      value="[datafield:excelTur]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="259347"
                                    visibility={this.state.isComp259347Visible}
                                    title={ReactSystemFunctions.translate(this.ml, 259347, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="681735"
                                      value="[datafield:toplamSure]"
                                      formatter="money-var-1"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="331355"
                                    title={ReactSystemFunctions.translate(this.ml, 331355, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="891653"
                                      value="[datafield:satirSayisi]"
                                      formatter="money-var-1"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="865635"
                                    title={ReactSystemFunctions.translate(this.ml, 865635, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="511973"
                                      value="[datafield:kontolTTutar]"
                                      formatter=""
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="481437"
                                    title={ReactSystemFunctions.translate(this.ml, 481437, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="176896"
                                      value="[datafield:faturanumaras]"
                                      formatter=""
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="343185"
                                    title={ReactSystemFunctions.translate(this.ml, 343185, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="302087"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IKartMeslekBirligiTRComponent_302087_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="delete"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontSize: "24px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KCol>
                          </KRow>

                          <KRow
                            id="983829"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 16,
                                paddingBottom: 0,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KCol
                              id="255450"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "dotted",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Button
                                id="350340"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.IKartMeslekBirligiTRComponent_350340_onClick();
                                }}
                                showCursorPointer
                                label={ReactSystemFunctions.translate(this.ml, 350340, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline"
                                  } as any
                                }
                              ></Button>
                            </KCol>

                            <KCol
                              id="743475"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <Button
                                id="93844"
                                visibility={this.state.isComp93844Visible}
                                label={ReactSystemFunctions.translate(this.ml, 93844, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline"
                                  } as any
                                }
                              ></Button>
                            </KCol>
                          </KRow>

                          <KRow
                            id="961435"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 16,
                                paddingBottom: 0,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KCol
                              id="126282"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "dotted",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></KCol>
                          </KRow>
                        </KPanel>
                      </KCol>
                    </KRow>

                    <KRow
                      id="940505"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="855690"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="254518"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="212112"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="820946"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible820946: false }, () => {
                      this.callCallbackFunction(820946);
                    });
                  }}
                  visible={this.state.NavVisible820946}
                  push={true}
                  children={
                    this.state.NavVisible820946 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible820946: false }, () => {
                            this.callCallbackFunction(820946);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="199855"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible199855: false }, () => {
                      this.callCallbackFunction(199855);
                    });
                  }}
                  visible={this.state.NavVisible199855}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible199855 && (
                      <CariIlgiliKisiler_Screen
                        onClose={() => {
                          this.setState({ NavVisible199855: false }, () => {
                            this.callCallbackFunction(199855);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="397141"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible397141: false }, () => {
                      this.callCallbackFunction(397141);
                    });
                  }}
                  visible={this.state.NavVisible397141}
                  width="50%"
                  push={true}
                  children={
                    this.state.NavVisible397141 && (
                      <CariSozlesmeler_Screen
                        onClose={() => {
                          this.setState({ NavVisible397141: false }, () => {
                            this.callCallbackFunction(397141);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="854058"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible854058: false }, () => {
                      this.callCallbackFunction(854058);
                    });
                  }}
                  visible={this.state.NavVisible854058}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible854058 && (
                      <AksiyonKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible854058: false }, () => {
                            this.callCallbackFunction(854058);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="402622"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible402622: false }, () => {
                      this.callCallbackFunction(402622);
                    });
                  }}
                  visible={this.state.NavVisible402622}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible402622 && (
                      <AksiyonKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible402622: false }, () => {
                            this.callCallbackFunction(402622);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="211229"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible211229: false }, () => {
                      this.callCallbackFunction(211229);
                    });
                  }}
                  visible={this.state.NavVisible211229}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible211229 && (
                      <CariIlgiliKisiler_Screen
                        onClose={() => {
                          this.setState({ NavVisible211229: false }, () => {
                            this.callCallbackFunction(211229);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="201256"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible201256: false }, () => {
                      this.callCallbackFunction(201256);
                    });
                  }}
                  visible={this.state.NavVisible201256}
                  width="50%"
                  push={true}
                  children={
                    this.state.NavVisible201256 && (
                      <CariSozlesmeler_Screen
                        onClose={() => {
                          this.setState({ NavVisible201256: false }, () => {
                            this.callCallbackFunction(201256);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KModal
                  id="836869"
                  visible={this.state.NavVisible836869}
                  onOk={() => {
                    this.setState({ NavVisible836869: false }, () => {
                      this.callCallbackFunction(836869);
                    });
                  }}
                  onCancel={() => {
                    this.setState({ NavVisible836869: false }, () => {
                      this.callCallbackFunction(836869);
                    });
                  }}
                  closable={true}
                  width="800px"
                  footer={null}
                  height="70vh"
                  children={
                    this.state.NavVisible836869 && (
                      <HataKontrol_Screen
                        onClose={() => {
                          this.setState({ NavVisible836869: false }, () => {
                            this.callCallbackFunction(836869);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                ></KModal>

                <KModal
                  id="570586"
                  visible={this.state.NavVisible570586}
                  onOk={() => {
                    this.setState({ NavVisible570586: false }, () => {
                      this.callCallbackFunction(570586);
                    });
                  }}
                  onCancel={() => {
                    this.setState({ NavVisible570586: false }, () => {
                      this.callCallbackFunction(570586);
                    });
                  }}
                  closable={true}
                  width="380px"
                  footer={null}
                  height="25vh"
                  children={
                    this.state.NavVisible570586 && (
                      <QuarterChange_Screen
                        onClose={() => {
                          this.setState({ NavVisible570586: false }, () => {
                            this.callCallbackFunction(570586);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                ></KModal>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(IKartMeslekBirligiTR_Screen))))
  )
);
export { tmp as IKartMeslekBirligiTR_Screen };
//export default tmp;
//export { tmp as IKartMeslekBirligiTR_Screen };
