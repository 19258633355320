import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IArsivList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IArsivList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  getUserInfo: any[];
  getUserInfo_dummy: any[];
  getFilterParametersByUserName: any[];
  getFilterParametersByUserName_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  spArsivList: any[];
  spArsivList_dummy: any[];
  spArsivListCount: any[];
  spArsivListCount_dummy: any[];
  FilterParametersSaveArsivList: number;
  FilterParametersSaveArsivList_dummy: number;
  getFormNo: any[];
  getFormNo_dummy: any[];
  isComp3650Visible: "visible" | "hidden";
  isComp871646Visible: "visible" | "hidden";
}

export class ArsivList_ScreenBase extends React.PureComponent<IArsivList_ScreenProps, any> {
  arsivlist_660947_value_kuikaTableRef: React.RefObject<any>;
  arsivlist_958309_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "333577ed-85fc-4415-a889-d70dddeb5d54",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625521, PropertyName: "value", Value: "ARŞİV" },
        { Id: 998378, PropertyName: "placeholder", Value: "Aranacak kelimeyi yazınız..." },
        { Id: 660947, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 789346, PropertyName: "title", Value: "İş No" },
        { Id: 854825, PropertyName: "value", Value: "[datafield:ticketno]" },
        { Id: 812977, PropertyName: "title", Value: "Başvuru Tarihi" },
        { Id: 679677, PropertyName: "value", Value: "[datafield:basvurutarihi]" },
        { Id: 152799, PropertyName: "title", Value: "Son Güncelleme" },
        { Id: 469334, PropertyName: "value", Value: "[datafield:sondegistirmetarihi]" },
        { Id: 945777, PropertyName: "title", Value: "Durum" },
        { Id: 885610, PropertyName: "value", Value: "[datafield:formstatu]" },
        { Id: 468136, PropertyName: "title", Value: "Başvuru Türü" },
        { Id: 408011, PropertyName: "value", Value: "[datafield:basvuruturu]" },
        { Id: 885016, PropertyName: "title", Value: "Proje Türü" },
        { Id: 352743, PropertyName: "value", Value: "[datafield:projeturu]" },
        { Id: 229962, PropertyName: "title", Value: "Bölüm" },
        { Id: 588234, PropertyName: "value", Value: "[datafield:dizibolumno]" },
        { Id: 243187, PropertyName: "title", Value: "Eser" },
        { Id: 231859, PropertyName: "value", Value: "[datafield:eserad]" },
        { Id: 688600, PropertyName: "title", Value: "Eser Sahibi" },
        { Id: 795938, PropertyName: "value", Value: "[datafield:esersahibi]" },
        { Id: 352211, PropertyName: "title", Value: "Başvuru Sahibi" },
        { Id: 726097, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 707219, PropertyName: "title", Value: "Proje Adı" },
        { Id: 772072, PropertyName: "value", Value: "[datafield:projead]" },
        { Id: 114383, PropertyName: "title", Value: "İlgili Kişi" },
        { Id: 737796, PropertyName: "value", Value: "[datafield:nick]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.arsivlist_998378_value_kuikaTextInputRef = React.createRef();
    this.arsivlist_660947_value_kuikaTableRef = React.createRef();
    this.arsivlist_958309_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      getUserInfo: [],
      getFilterParametersByUserName: [],
      SetValueOf: "",
      spArsivList: [],
      spArsivListCount: [],
      FilterParametersSaveArsivList: "",
      getFormNo: [],
      isComp3650Visible: "hidden",
      isComp871646Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("arsivlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ArsivListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("arsivlist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("arsivlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ArsivListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      arsivlist_998378_value: this.state.getFilterParametersByUserName?.at?.(0)?.arsivListSearch ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ArsivListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ArsivList/ArsivListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.getFilterParametersByUserName = result?.data.getFilterParametersByUserName;
    formVars.arsivlist_998378_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].arsivListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].arsivListSearch
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ArsivListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ArsivListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("arsivlist_998378_value", "string"),
      ReactSystemFunctions.toString(
        this,
        stateVars.getFilterParametersByUserName?.length > 0
          ? stateVars.getFilterParametersByUserName[0].arsivListSearch
          : this.state.getFilterParametersByUserName?.length > 0
          ? this.state.getFilterParametersByUserName[0].arsivListSearch
          : null
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ArsivListPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ArsivListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_998378_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ArsivList/ArsivListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spArsivList = result?.data.spArsivList;

    stateVars.spArsivListCount = result?.data.spArsivListCount;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ArsivListPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ArsivListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.arsivlist_998378_value = ReactSystemFunctions.toString(
      this,
      stateVars.getFilterParametersByUserName?.length > 0
        ? stateVars.getFilterParametersByUserName[0].arsivListSearch
        : this.state.getFilterParametersByUserName?.length > 0
        ? this.state.getFilterParametersByUserName[0].arsivListSearch
        : null
    );

    stateVars.dataSource_660947 = this.state.spArsivList;
    stateVars.isComp3650Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "arsivlist_660947_value", "acil"), true) === true
        ? "visible"
        : "hidden";
    formVars.arsivlist_958309_total = ReactSystemFunctions.value(
      this,
      stateVars.spArsivListCount?.length > 0
        ? stateVars.spArsivListCount[0].sumCount
        : this.state.spArsivListCount?.length > 0
        ? this.state.spArsivListCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ArsivListComponent_653137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ArsivList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "464411",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ArsivListComponent_998378_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      ArsivListSearch_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_998378_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ArsivList/ArsivListComponent_998378_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.spArsivList = result?.data.spArsivList;

    stateVars.spArsivListCount = result?.data.spArsivListCount;
    stateVars.FilterParametersSaveArsivList = result?.data.filterParametersSaveArsivList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ArsivListComponent_998378_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ArsivListComponent_998378_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.spArsivList;
    formVars.arsivlist_958309_total = ReactSystemFunctions.value(
      this,
      stateVars.spArsivListCount?.length > 0
        ? stateVars.spArsivListCount[0].sumCount
        : this.state.spArsivListCount?.length > 0
        ? this.state.spArsivListCount[0].sumCount
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ArsivListComponent_871646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "arsivlist_660947_value", "btKod"),
        "number"
      ),
      PTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "arsivlist_660947_value", "pftKod"),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ArsivList/ArsivListComponent_871646_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getFormNo = result?.data.getFormNo;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ArsivListComponent_871646_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ArsivListComponent_871646_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmGuid",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "arsivlist_660947_value", "id"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmBavuruTuruID",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "arsivlist_660947_value", "basvuruTuruID"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "NewDisplay",
        ReactSystemFunctions.convertToTypeByName("D", "string")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuBankaV2",
        "prmNerdenGeldi",
        ReactSystemFunctions.convertToTypeByName("info", "string")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.ArsivListComponent_871646_onClick2_,
        "ArsivList",
        "BFormuBankaV2",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ArsivListComponent_871646_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ArsivListComponent_871646_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getFormNo?.length > 0
            ? stateVars.getFormNo[0].formNo
            : this.state.getFormNo?.length > 0
            ? this.state.getFormNo[0].formNo
            : null
        ),
        "0"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmGuid",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "arsivlist_660947_value", "id"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmBavuruTuruID",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "arsivlist_660947_value", "basvuruTuruID"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "NewDisplay",
        ReactSystemFunctions.convertToTypeByName("D", "string")
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmProjeTuruID",
        ReactSystemFunctions.convertToTypeByName(
          ReactSystemFunctions.value(this, "arsivlist_660947_value", "projeTuruID"),
          "Guid"
        )
      );
      KuikaAppManager.addToPageInputVariables(
        "BFormuTicari",
        "prmNerdenGeldi",
        ReactSystemFunctions.convertToTypeByName("info", "string")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "ArsivList",
        "BFormuTicari",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ArsivListComponent_578618_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "IsTakipKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "arsivlist_660947_value", "id"), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "IsTakipKart",
      "prmNerdenGeldi",
      ReactSystemFunctions.convertToTypeByName("istakip", "string")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ArsivList",
      "IsTakipKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ArsivListComponent_958309_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Rol_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.getUserInfo?.length > 0
            ? stateVars.getUserInfo[0].rolName
            : this.state.getUserInfo?.length > 0
            ? this.state.getUserInfo[0].rolName
            : null
        ),
        "string"
      ),
      Search_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_998378_value", "value", "", "", "")
        ),
        "string"
      ),
      PageNumber_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_958309_value", "current", "", "", "")
        ),
        "number"
      ),
      RowsOfPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "arsivlist_958309_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ArsivList/ArsivListComponent_958309_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getUserInfo = result?.data.getUserInfo;
    stateVars.spArsivList = result?.data.spArsivList;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ArsivListComponent_958309_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ArsivListComponent_958309_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_660947 = this.state.spArsivList;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [464411] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ArsivListPageInit();
    }
  }
}
